import React, { useState, useEffect } from 'react'
import { projects } from '../db/db'
import ProjectCard from './ProjectCard'

const Projects = () => {

    const [currentProjects, setCurrentProjects] = useState(projects.slice(0, 4))
    const [currentPage, setCurrentPage] = useState(0)
    // const changePage = (page) => {
    //     setCurrentProjects(projects.slice(page, page*4))
    // }
    useEffect(() => {
        // console.log(currentPage)
        const startIndex = currentPage * 4
        setCurrentProjects(projects.slice(startIndex, startIndex + 4))
    }, [currentPage])
    return (
        <>
            {/* Courses Area */}
            <div className="courses-area pb-70">
                <div className="container">
                    <div className="section-title text-center">
                        <span className="sp-after">Our Projects</span>
                        <h2 className="h2-color2">Latest Projects List</h2>
                        <p>
                            At Xtend, we take pride in delivering quality work to our clients. Below are some of the projects we've worked on:{" "}
                        </p>
                    </div>
                    <div className="row pt-45">
                        {
                            currentProjects.map((project, index) => {
                                return (
                                    <ProjectCard
                                        key={project.title + index}
                                        title={project.title}
                                        img={project.img}
                                        description={project.description}
                                        link={project.link}
                                        badge={project.badge}
                                    />
                                )
                            })
                        }
                        {/* <ProjectCard
                            title="Hosting Services"
                            img="assets/img/courses/courses-img3.png"
                            description="A hosting service is a type of service that provides server space for storing website files and making them accessible through the internet. Hosting services range from shared hosting, where multiple websites share server resources, to dedicated hosting, where a business has full control over a server. Hosting services also provide various features such as email accounts, SSL certificates, and database management tools to improve website performance and security."
                            link="/hosting"
                            badge="Soon"
                        /> */}

                        <div className="col-lg-12 col-md-12">
                            <div className="pagination-area text-center">
                                {/* <a href="/" className="prev page-numbers"
                                    onClick={(e) => {
                                        e.preventDefault()
                                    }}
                                >
                                    <i className="bx bx-chevron-left" />
                                </a> */}
                                <span role={"button"} className="prev page-numbers"
                                    onClick={() => setCurrentPage(currentPage === 0 ? 0 : currentPage - 1)}
                                >
                                    <i className="bx bx-chevron-left" />
                                </span>
                                <span role={"button"} className={currentPage === 0 ? "page-numbers current" : "page-numbers"} aria-current="page"
                                    onClick={() => setCurrentPage(0)}
                                >
                                    1
                                </span>
                                <span role={"button"} className={currentPage === 1 ? "page-numbers current" : "page-numbers"} aria-current="page"
                                    onClick={() => setCurrentPage(1)}
                                >
                                    2
                                </span>
                                {/* <span role={"button"} className={currentPage === 2 ? "page-numbers current" : "page-numbers"} aria-current="page"
                                    onClick={() => setCurrentPage(2)}
                                >
                                    3
                                </span> */}

                                {/* <a href="#" className="page-numbers current">
                                    2
                                </a>
                                <a href="#" className="page-numbers">
                                    3
                                </a>
                                <a href="#" className="page-numbers">
                                    4
                                </a>
                                <a href="#" className="next page-numbers">
                                    <i className="bx bx-chevron-right" />
                                </a> */}
                                <span role={"button"} className="next page-numbers"
                                    onClick={() => setCurrentPage(currentPage === 2 ? 2 : currentPage + 1)}
                                >
                                    <i className="bx bx-chevron-right" />
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* Courses Area End */}
        </>

    )
}

export default Projects