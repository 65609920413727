export const services = {
    web_development: {
        title: "Web development",
        description: "Web development is an essential service that involves the creation and maintenance of websites. A web developer uses their knowledge of programming languages such as HTML, CSS, and JavaScript to design and develop the user interface, structure, and functionality of a website. They need to ensure that the website is optimized for web browsers and is responsive across different devices.\n \nWeb development also involves backend development, which includes managing databases, server- side scripting, and APIs.These features enable the website to perform complex functions such as data processing, user authentication, and interactions with other web services.\n \nIn addition to technical skills, web developers need to have an understanding of web design principles, user experience, and search engine optimization.This ensures that the website is visually appealing, easy to use, and accessible to users.Web development also plays an important role in establishing a brand's online presence, building customer trust, and generating leads and conversions.\n \nWith the growing digitalization, web development has become an increasingly critical service for businesses of all sizes.It enables businesses to reach customers globally, showcase products and services, provide customer support, and facilitate online transactions.The demand for web development services continues to grow as technology evolves, and businesses look to stay ahead of the competition by providing innovative and engaging digital experiences for their customers.",
    },
    app_development: {
        title: "App development",
        description: "App development is a crucial service that involves the creation of software applications that run on mobile devices such as smartphones, tablets, and wearables. The service includes designing, developing, testing, and deploying applications for various platforms, including iOS, Android, and Windows.\n \nThe process of app development starts with identifying user requirements, followed by designing an intuitive user interface(UI) that enhances user experience(UX).App developers then create efficient code, test the application thoroughly, and ensure compatibility with different devices, screen sizes, resolutions, and operating systems.\n \nIn addition to functionality, app developers also consider factors such as security, scalability, performance, and usability while creating apps.They integrate APIs, cloud services, and other third- party tools to enhance app features and functionalities.\n \nSuccessful case studies of app development include popular applications such as Facebook, Spotify, and TikTok, which have transformed their respective industries.Companies use app development services to improve customer engagement, increase brand awareness, generate revenue through in -app purchases and advertising, and streamline business processes.\n \nKeywords associated with app development include native app development, hybrid app development, cross - platform app development, UI design, UX, API integration, cloud services, agile methodology, DevOps, and continuous integration and delivery(CI / CD).\n \nIt is crucial to partner with an experienced app development company that can provide end - to - end solutions to help businesses achieve their desired outcomes.By leveraging app development services, companies can create innovative solutions that meet the evolving needs of their customers and stay competitive in today's digital landscape.",
    },
    web3: {
        title: "Web3",
        description: "Web3 is an emerging service that aims to revolutionize the internet by enabling decentralized applications (dApps) and blockchain technology. Web3 is a new vision for the internet that aims to create an open, decentralized network that can't be controlled or censored by any single entity.\n \n Web3 is based on blockchain technology, which is a distributed ledger that securely records transactions between parties without the need for intermediaries.It allows for secure, transparent, and immutable data storage and transfer, making it ideal for use in various industries, including finance, healthcare, and supply chain management.\n \nThe core components of web3 include decentralized identity, decentralized storage, decentralized communication, and decentralized computing.These components are designed to enable users to interact with dApps directly, without the need for intermediaries such as social media platforms or e- commerce websites.\n \nSuccessful case studies of web3 include popular blockchain - based applications such as Ethereum, Polkadot, and Solana, which have transformed the way we perceive the internet.Web3 has the potential to decentralize the internet, giving power back to the users and enabling a more fair, transparent and equitable online ecosystem.\n \nKeywords associated with web3 include blockchain technology, dApps, decentralized identity, decentralized storage, decentralized communication, decentralized computing, smart contracts, crypto, and NFTs.\n \nIt is crucial to partner with an experienced web3 development company that can provide end - to - end solutions and help businesses leverage the full potential of web3.By adopting web3, businesses can unlock new opportunities for innovation and growth, while also promoting more secure, trustworthy, and decentralized interactions between customers, partners, and stakeholders.",
    },
    hosting: {
        title: "Domain, hosting, and VPS",
        description: "Domain, hosting, and VPS services are essential components of web infrastructure that enable businesses to establish a robust online presence. These services help businesses acquire domain names, host their websites on servers, and access virtual private servers (VPS) for more advanced computing needs.\n \nDomain name registration is the process of acquiring a unique web address that can be used to identify a website on the internet.Domain registration services allow businesses to secure domain names that align with their brand identity and improve their visibility in search engines.\n \nHosting services provide businesses with server space to store their website files and data.Hosting services range from shared hosting plans to dedicated server options, depending on the size and complexity of a business's website.\n \nVPS services offer businesses greater flexibility and control over their online infrastructure by providing access to virtual machines running on powerful servers.This service allows businesses to customize their computing environment and scale resources according to their needs.\n \nSuccessful case studies of domain, hosting, and VPS services include popular providers such as GoDaddy, Bluehost, and DigitalOcean, which have helped businesses establish and maintain reliable online presences.\n \nKeywords associated with domain, hosting, and VPS services include domain registration, website hosting, shared hosting, dedicated hosting, VPS hosting, cloud hosting, managed hosting, and scalability.\n \nIt is important for businesses to partner with a reliable domain, hosting, and VPS service provider that can offer secure, flexible, and scalable solutions.By leveraging these services, businesses can establish a robust online presence, improve their website performance, and ensure a consistent user experience for their customers.",
    },
    ai: {
        title: "Artificial intelligence and machine learning",
        description: "Artificial intelligence (AI) and machine learning (ML) are rapidly evolving technologies that offer businesses incredible opportunities to automate processes, gain insights, and make informed decisions. AI and ML services involve the development, deployment, and integration of algorithms, tools, and models to enable machines to learn from data and improve their performance over time.\n \nThe key components of AI and ML services include data analysis, model development, training and testing, and deployment.These services leverage vast amounts of data to build predictive models that can help businesses optimize processes, reduce costs, and increase efficiency.\n \nSuccessful case studies of AI and ML services include applications such as recommendation engines, fraud detection systems, and natural language processing(NLP) tools, which have transformed various industries, including finance, healthcare, and e- commerce.\n \nKeywords associated with AI and ML services include deep learning, neural networks, computer vision, NLP, predictive analytics, decision making, automation, and optimization.\n \nIt is essential for businesses to partner with an experienced AI and ML service provider that can provide end - to - end solutions and customized applications according to their unique needs.AI and ML services can help businesses streamline operations, reduce human error, and gain valuable insights into customer behavior, leading to improved decision - making and increased revenue.",
    },
    mobile_app_development: {
        title: "Mobile app development",
        description: "Mobile app development is a service that involves the creation of applications for mobile devices such as smartphones and tablets. This service encompasses the entire process of designing, developing, testing, and deploying applications on various platforms including iOS, Android, and Windows.\n \nThe primary components of mobile app development include understanding user requirements, designing an intuitive user interface(UI), developing efficient code, and ensuring compatibility with different devices, screen sizes, resolutions, and operating systems.\n \nIn addition to functionality, app developers also consider factors such as security, scalability, performance, and usability while creating apps.They integrate APIs, cloud services, and other third- party tools to enhance app features and functionalities.\n \nSuccessful case studies of mobile app development include popular applications such as Uber, Airbnb, and Instagram, which have revolutionized their respective industries.App development has enabled companies to improve customer engagement, increase brand awareness, generate revenue through in -app purchases and advertising, and streamline business processes.\n \nKeywords associated with mobile app development include native app development, hybrid app development, cross - platform app development, UI design, UX, API integration, cloud services, agile methodology, DevOps, and continuous integration and delivery(CI / CD).\n \nIt is crucial to partner with an experienced mobile app development company that can provide end - to - end solutions and help businesses achieve their desired outcomes.By leveraging mobile app development services, companies can create innovative solutions that meet the evolving needs of their customers and stay competitive in today's digital landscape.",
    },

}

export const projects = [
    {
        title: "Hosting Services",
        img: "assets/img/projects/project-hosting.png",
        description: "A hosting service is a type of service that provides server space for storing website files and making them accessible through the internet. Hosting services range from shared hosting, where multiple websites share server resources, to dedicated hosting, where a business has full control over a server. Hosting services also provide various features such as email accounts, SSL certificates, and database management tools to improve website performance and security.",
        link: "https://x-tend.solutions/hosting",
        badge: "Soon",
    },
    {
        title: "New Chat GPT-4 Inference",
        img: "assets/img/projects/courses-img1.png",
        description: "Chat GPT is a chatbot developed using OpenAI's technology that uses deep learning algorithms to generate contextually relevant responses. It can be customized for different industries and applications, improving customer engagement and streamlining business processes.",
        link: "https://x-tend.solutions/chat",
        badge: false,
    },
    {
        title: "Xtend Trading (DApp)",
        img: "assets/img/projects/project-trading.png",
        description: "Automated trading is a project that uses computer algorithms to execute trades on centralized and decentralized trading platforms automatically. This technology allows traders to set predefined rules for buying and selling assets based on specific market conditions, enabling faster and more accurate trading decisions. In the centralized platform, the automation is done through an exchange's API, while in the decentralized platform, it is done through smart contracts on a blockchain network.",
        link: "/",
        badge: "Soon",
    },
    {
        title: "Xtend Mailling system (DApp)",
        img: "assets/img/projects/courses-img3.png",
        description: "The email system project is a decentralized application (dApp) that enables users to communicate with each other on blockchain networks using an encrypted and secure email system. This dApp ensures privacy and security for the users' communication while leveraging the benefits of blockchain technology.",
        link: "https://DMail.xtend-eg.com/",
        badge: "Soon",
    },
    {
        title: "Xtend Vault (DApp)",
        img: "assets/img/projects/project-vault.png",
        description: "The Vault is a decentralized application (dApp) that securely saves and encrypts users' important data across multiple blockchain networks, ensuring maximum security and redundancy.",
        link: "https://vault.xtend-eg.com/",
        badge: "Soon",
    },

    // {
    //     title: "COVID-19 Self Assessment",
    //     img: "assets/img/projects/courses-img2.png",
    //     description: "As a participation in the world's most critical issue this year, we have developed a self-assessment website to help determine whether a person should be tested for COVID-19 or not in Arabic Language. The decision tree has been deduced from Alberta Health Services test. COVID-19 Check",
    //     link: "/covid19check",
    //     badge: false,
    // },
    {
        title: "Full Translation System",
        img: "assets/img/projects/courses-img3.png",
        description: "Development and deployment of a full translation system using Python Flask and Amazon web services. The customer requirements was to develop a system that is able to web pages, texts, PPTX, and DOCX files.",
        link: "/",
        badge: false,
    },
    {
        title: "National ID card reader",
        img: "assets/img/projects/project-ocr.png",
        description: "Copying information from national ID card is needed in each and every place we go to everyday, either to record it or to fill it in an application form. An error in only one digit can delay an urgent process for days. Therefore, it is necessary to develop software capable of scanning the card rapidly, and with high accuracy, extracting personal information from it, and providing this information to the user to reduce the time needed for copying along with typos. This project performs its tasks using Python Open-CV libraries. GitHub",
        link: "/",
        badge: false,
    },
    {
        title: "Facial expression recognition",
        img: "assets/img/projects/project-facial-exp.png",
        description: "A facial expression recognition developed using KERAS; The goal of the project is to apply deep learning techniques to recognize the key seven human emotions: anger, disgust, fear, happiness, sadness, surprise and neutrality. GitHub",
        link: "/",
        badge: false,
    },
    // {
    //     title: "",
    //     img: "",
    //     description: "",
    //     link: "/",
    //     badge: false,
    // },
]

export const team = [
    {
        name: "Mustafa Fahmy",
        title: "Software Engineer",
        img: "assets/img/team/mf.jpg",
        github: "https://github.com/MustafaFahmy93",
        linkedin: "https://www.linkedin.com/in/mustafafahmy/",
        facebook: "https://www.facebook.com/",
        frameColor: ""

    },
    {
        name: "Mostafa Atef",
        title: "Project Manager",
        img: "assets/img/team/atef.png",
        github: "https://github.com/MostafaAtef96",
        linkedin: "https://www.linkedin.com/in/mostafaatef96/",
        facebook: "https://www.facebook.com/",
        frameColor: "-2"

    },
    {
        name: "Mustafa Maged",
        title: "Data Engineer",
        img: "assets/img/team/maged.png",
        github: "https://github.com/",
        linkedin: "https://www.linkedin.com/in/mustafa-maged-de",
        frameColor: ""

    },
    {
        name: "Ola Hamdy",
        title: "Ai Engineer",
        img: "assets/img/team/ola.png",
        github: "https://github.com/OlaHamdy3",
        linkedin: "https://www.linkedin.com/in/olahamdy3",
        frameColor: "-1"

    },
    {
        name: "Aliaa",
        title: "Web Developer",
        img: "assets/img/team/aliaa.png",
        github: "https://github.com/AliaaMahmoud",
        linkedin: "https://www.linkedin.com/in/aliaa-shalabi",
        facebook: "https://www.facebook.com/",
        frameColor: "-2"

    },
    {
        name: "Yousif Hmada",
        title: "Back-End Engineer",
        img: "assets/img/team/youssef.png",
        github: "https://github.com/",
        linkedin: "https://www.linkedin.com/in/yousifhmada",
        facebook: "https://www.facebook.com/",
        frameColor: "-3"

    },

]