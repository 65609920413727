import './App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Routes, Route, Navigate } from 'react-router-dom'
import Home from './components/Home';
import ServiceDetails from './components/ServiceDetails';
import Projects from './components/Projects';
import Header from './components/Header';
import Footer2 from './components/Footer2';

function App() {

  return (
    <HelmetProvider>
      <Helmet>

        <script defer={true} src={"/assets/js/meanmenu.js"} type="text/javascript" />
        <script defer={true} src={"/assets/js/contact-form-script.js"} type="text/javascript" />
        <script defer={true} src={"/assets/js/custom.js"} type="text/javascript" />
      </Helmet>
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/projects"} element={<Projects />} />
        <Route path={"/service/:id"} element={
          <>
            <Header />
            <ServiceDetails />
            <Footer2 />
          </>
        } />

        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>

    </HelmetProvider>
  );
}

export default App;
