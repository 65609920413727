import React, { useState } from 'react'

const ProjectCard = ({
    title,
    img,
    description,
    link,
    badge
}) => {
    const minTextLength = 250
    const [desc, setDesc] = useState({ full: false, text: description.slice(0, minTextLength) })
    const toggleText = () => {
        setDesc(desc.full ? { full: false, text: description.slice(0, minTextLength) } : { full: true, text: description })
    }
    return (
        <div className="col-lg-6">
            <div className="courses-card">
                <div className="images">
                    <a href={link} target="_blank">
                        <img src={img} alt="Images" style={{ width: "160px" }} />
                    </a>
                    {
                        badge &&
                        <div className="rate">
                            <a href={link} target="_blank">{badge}</a>
                        </div>

                    }

                </div>

                <h3>
                    <a href={link} target="_blank">
                        {title}
                    </a>
                </h3>
                <p>
                    {desc.text}
                    {
                        desc.full ? <br /> : "... "
                    }
                    <a href='?#'
                        onClick={(e) => {
                            e.preventDefault()
                            toggleText()
                        }}
                    >

                        {desc.full ? "Show less" : " Show more"}
                    </a>
                </p>

            </div>
        </div>
    )
}

export default ProjectCard