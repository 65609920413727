import React from 'react'

const AboutUs = () => {
    return (
        <>
            {/* About Area */}
            <div className="about-area pt-100 pb-70">
                <div className="container-fluid">
                    <div className="container-max">
                        <div className="row">
                            <div className="col-lg-6">
                                {/* <div className="about-img"> */}
                                {/* <img src="assets/img/about/about-img1.png" alt="Images" /> */}
                                <div className="about-img-2">
                                    <img src="assets/img/about/about-img3.png" alt="about"></img>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-content">
                                    <div className="section-title">
                                        <span className="sp-after">About us</span>
                                        <h2 className="h2-color">
                                            Grow Your Business <b>With Our Agency</b>
                                        </h2>
                                    </div>
                                    <h3>
                                        We have 5 years Our strategy includes consistently evolving, to
                                        ensure we’re producing exceptional Software for business.
                                    </h3>
                                    <p>
                                        At Xtend, our mission is to provide businesses with the tools they need to succeed in today's digital landscape. We believe that by combining cutting-edge technology with innovative strategies, we can help businesses achieve their goals and reach new heights of success. Our values include:






                                    </p>
                                    <ul>
                                        <li>Innovation</li>
                                        <li>Transparency</li>
                                        <li> Customer satisfaction</li>
                                        <li>Continuous improvement</li>
                                        <li>Teamwork</li>
                                    </ul>
                                    {/* <div className="about-btn">
                                        <a href="#" className="default-btn">
                                            Learn more
                                            <i className="bx bx-plus" />
                                        </a>
                                        <a
                                            href="https://www.youtube.com/watch?v=07d2dXHYb94"
                                            className="play-btn popup-btn"
                                        >
                                            <i className="flaticon-play-button" />
                                            <span>
                                                {" "}
                                                Watch 1min <br />
                                                Play demo
                                            </span>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-shape">
                    <div className="shape-1">
                        <img src="assets/img/about/about-shape1.png" alt="Images" />
                    </div>
                    <div className="shape-2">
                        <img src="assets/img/shape/shape1.png" alt="Images" />
                    </div>
                    <div className="shape-3">
                        <img src="assets/img/shape/shape2.png" alt="Images" />
                    </div>
                    <div className="shape-4">
                        <img src="assets/img/shape/shape3.png" alt="Images" />
                    </div>
                    <div className="shape-5">
                        <img src="assets/img/shape/shape4.png" alt="Images" />
                    </div>
                </div>
            </div>
            {/* About Area End */}
        </>

    )
}

export default AboutUs