import React from 'react'

const Services = () => {
    return (
        <>
            {/* Service Area */}
            <div className="service-area pt-100 pb-70">
                <div className="container">
                    <div className="section-title">
                        <span className="sp-after">Services</span>
                        <h2 className="h2-color">
                            We’re Flexible to <b>Provide You Best</b>
                        </h2>
                    </div>
                    <div className="row pt-45">
                        <div className="col-lg-4">
                            <div className="service-card-into">
                                <div className="service-card">
                                    <a href="service-details.html">
                                        <img src="assets/img/service/service-icon3.png" alt="Images" />
                                    </a>
                                    <h3>
                                        <a href="service-details.html">App Development</a>
                                    </h3>
                                    <p>
                                        Our team of experienced developers can create custom software solutions tailored to your specific needs, whether it's a new application to enhance efficiency or a custom database to store and manage data.
                                    </p>
                                    <div className="service-card-shape">
                                        <img src="assets/img/service/service-shape.png" alt="Images" />
                                    </div>
                                </div>
                                <div className="service-dots">
                                    <img src="assets/img/service/dots.png" alt="Images" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="service-card">
                                <a href="service-details.html">
                                    <img src="assets/img/service/service-icon2.png" alt="Images" />
                                </a>
                                <h3>
                                    <a href="service-details.html">Web development</a>
                                </h3>
                                <p>
                                    We offer a full range of web development services, from building brand-new websites to maintaining and updating existing ones. Our team of experts can develop responsive and mobile-friendly websites that are user-friendly and optimized for search engines.
                                </p>
                                <div className="service-card-shape">
                                    <img src="assets/img/service/service-shape.png" alt="Images" />
                                </div>
                            </div>
                            <div className="service-card">
                                <a href="service-details.html">
                                    <img src="assets/img/service/service-icon6.png" alt="Images" />
                                </a>
                                <h3>
                                    <a href="service-details.html">Web3</a>
                                </h3>
                                <p>
                                    The web3 solutions leverage the power of blockchain technology to provide enhanced security, transparency, and decentralization. The team develops decentralized applications (DApps) and smart contracts that enable trustless interactions between parties.
                                </p>
                                <div className="service-card-shape">
                                    <img src="assets/img/service/service-shape.png" alt="Images" />
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4">
                            <div className="service-card">
                                <a href="service-details.html">
                                    <img src="assets/img/service/service-icon4.png" alt="Images" />
                                </a>
                                <h3>
                                    <a href="service-details.html">Domain, Hosting, and VPS Services</a>
                                </h3>
                                <p>
                                    Xtend provides reliable and secure infrastructure for online presence. The company offers a range of hosting options, including shared hosting, VPS hosting, and dedicated servers, as well as domain registration and management services.
                                </p>
                                <div className="service-card-shape">
                                    <img src="assets/img/service/service-shape.png" alt="Images" />
                                </div>
                            </div>
                            <div className="service-card">
                                <a href="service-details.html">
                                    <img src="assets/img/service/service-icon5.png" alt="Images" />
                                </a>
                                <h3>
                                    <a href="service-details.html">Artificial intelligence and machine learning</a>
                                </h3>
                                <p>
                                    We utilize cutting-edge AI and machine learning technologies to help businesses automate tasks, analyze complex data sets, and gain insights into customer behavior. Our team can help integrate these technologies into your workflows and systems for greater efficiency and productivity.


                                </p>
                                <div className="service-card-shape">
                                    <img src="assets/img/service/service-shape.png" alt="Images" />
                                </div>
                            </div>
                            <div className="service-card">
                                <a href="service-details.html">
                                    <img src="assets/img/service/service-icon1.png" alt="Images" />
                                </a>
                                <h3>
                                    <a href="service-details.html">Mobile app development</a>
                                </h3>
                                <p>
                                    Our team can build mobile applications for iOS and Android that are customized to meet the unique needs of your business. From UI/UX design to coding and testing, we handle every aspect of the development process.
                                </p>
                                <div className="service-card-shape">
                                    <img src="assets/img/service/service-shape.png" alt="Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-shape">
                    <div className="service-bg">
                        <img src="assets/img/service/service-bg.png" alt="Images" />
                    </div>
                    <div className="shape1">
                        <img src="assets/img/shape/shape4.png" alt="Images" />
                    </div>
                    <div className="shape2">
                        <img src="assets/img/shape/shape2.png" alt="Images" />
                    </div>
                </div>
            </div>
            {/* Service Area End */}
        </>

    )
}

export default Services