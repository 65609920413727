import React from 'react'

const News = () => {
    return (
        <>
            {/* Blog Area */}
            <div className="blog-area pt-100 pb-70">
                <div className="container">
                    <div className="section-title text-center">
                        <span className="sp-before sp-after">Our Blogs</span>
                        <h2 className="h2-color2">Latest Valuable Insights</h2>
                        <p className="margin-auto">
                            {/* Stay up-to-date with the latest news, tips, and trends in software development. */}
                            Check out our latest press releases to stay up-to-date with our activities, achievements, and news.
                        </p>
                    </div>
                    <div className="row pt-45">
                        <div className="col-lg-4 col-md-6">
                            <div className="blog-card">
                                <a href="https://akhbarak.net/coronatest" target="_blank">
                                    {/* <img src="assets/img/blog/blog1.png" alt="Images" /> */}
                                    <img src="assets/img/news/news_1.png" alt="news_1" />
                                </a>
                                <div className="content">
                                    <ul>
                                        <li>
                                            <i className="bx bx-time-five" />
                                            18 March 2020
                                        </li>
                                        <li>
                                            <i className="bx bx-purchase-tag-alt" />
                                            <a href="#">Health</a>
                                        </li>
                                    </ul>
                                    <h3>
                                        <a href="https://akhbarak.net/coronatest" target="_blank">
                                            COVID-19 Self Assessment
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="blog-card">
                                <a href="https://web.facebook.com/CairoScene/posts/pfbid0NV6sHUsDmt5rWjRsMq88mcaUNjSJv9eK9f8RqahoiaYbyqxL3p6ZqB3MUUrDtwgGl?__cft__[0]=AZUiLPEwjqhUSp6KvS2MMRyhzMFP1kvRwwV8S9AkffDwK_NjAVi694ZBJjdgY_BEk1DD1v_tG1aQ4t30tspjl_b7KaAQaPaCi0ure1bGgRd5JnwPnRSqgI59jMmRWG9XDLayVEULvcQnt9Oj6rHZXQ0ewMHvVHQoBsg7UAB_dJhNjXLzxl55Odb5xFBsTDYsnbT_nsuuJ0_gUt5Mn_fZe2ev&__tn__=%2CO%2CP-y-R" target="_blank">
                                    {/* <img src="assets/img/blog/blog2.png" alt="Images" /> */}
                                    <img src="assets/img/news/news_2.jpg" alt="news_1" />
                                </a>
                                <div className="content">
                                    <ul>
                                        <li>
                                            <i className="bx bx-time-five" />
                                            28 June 2020
                                        </li>
                                        <li>
                                            <i className="bx bx-purchase-tag-alt" />
                                            <a href="#">Web</a>
                                        </li>
                                    </ul>
                                    <h3>
                                        <a href="https://web.facebook.com/CairoScene/posts/pfbid0NV6sHUsDmt5rWjRsMq88mcaUNjSJv9eK9f8RqahoiaYbyqxL3p6ZqB3MUUrDtwgGl?__cft__[0]=AZUiLPEwjqhUSp6KvS2MMRyhzMFP1kvRwwV8S9AkffDwK_NjAVi694ZBJjdgY_BEk1DD1v_tG1aQ4t30tspjl_b7KaAQaPaCi0ure1bGgRd5JnwPnRSqgI59jMmRWG9XDLayVEULvcQnt9Oj6rHZXQ0ewMHvVHQoBsg7UAB_dJhNjXLzxl55Odb5xFBsTDYsnbT_nsuuJ0_gUt5Mn_fZe2ev&__tn__=%2CO%2CP-y-R" target="_blank">
                                            SOFTAWARE DEVELOPMENT COMPANY XTEND'S WEBSITE ALLOWS YOU TO AVOID CROWDING UP AT THE MOSQUE
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div className="blog-card">
                                <a href="blog-details.html" target="_blank">
                                    <img src="assets/img/blog/blog7.png" alt="Images" />
                                </a>
                                <div className="content">
                                    <ul>
                                        <li>
                                            <i className="bx bx-time-five" />
                                            19 June 2023
                                        </li>
                                        <li>
                                            <i className="bx bx-purchase-tag-alt" />
                                            <a href="#">Empty</a>
                                        </li>
                                    </ul>
                                    <h3>
                                        <a href="blog-details.html" target="_blank">
                                            Empty
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="blog-shape">
                    <div className="shape1">
                        <img src="assets/img/shape/shape1.png" alt="Images" />
                    </div>
                    <div className="shape2">
                        <img src="assets/img/shape/shape5.png" alt="Images" />
                    </div>
                    <div className="shape3">
                        <img src="assets/img/shape/shape4.png" alt="Images" />
                    </div>
                    <div className="shape4">
                        <img src="assets/img/shape/shape6.png" alt="Images" />
                    </div>
                </div>
            </div>
            {/* Blog Area End */}
        </>

    )
}

export default News