import React from 'react'
import { team } from '../db/db'

const Team = () => {
    return (
        <>
            {/* Team Area */}
            <div className="team-area pt-100 pb-70">
                <div className="container-fluid p-0 m-0">
                    <div className="section-title text-center">
                        <span className="sp-after">Our Specialists</span>
                        <h2 className="h2-color">
                            Meet Our <b>Leadership Team</b>
                        </h2>
                        <p>
                            Our team is made up of highly skilled professionals who are passionate about what they do. From developers to designers, our team members have years of experience in their respective fields and are dedicated to delivering exceptional results.
                        </p>
                    </div>
                    <div className="team-slider owl-carousel owl-theme pt-45">
                        {
                            team.map((member, index) => {
                                return (
                                    <div className="team-item" key={index}>
                                        <a href="team.html" className={"team-img team-bg-before" + member.frameColor}>
                                            <img src={member.img}

                                                alt={member.name} />
                                        </a>
                                        <div className="team-content">
                                            <h3>
                                                <a href="team.html">{member.name}</a>
                                            </h3>
                                            <span>{member.title}</span>
                                            <ul className="social-link">
                                                <li>
                                                    <a href={member.github} target="_blank">
                                                        <i className="bx bxl-github" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={member.linkedin} target="_blank">
                                                        <i className="bx bxl-linkedin" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={member.facebook} target="_blank">
                                                        <i className="bx bxl-facebook" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href="#" target="_blank">
                                                        <i className="bx bxl-youtube" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
                <div className="team-shape">
                    <div className="team-shape1">
                        <img src="assets/img/shape/team-shape.png" alt="Images" />
                    </div>
                    <div className="team-shape2">
                        <img src="assets/img/shape/shape7.png" alt="Images" />
                    </div>
                    <div className="team-shape3">
                        <img src="assets/img/shape/shape6.png" alt="Images" />
                    </div>
                    <div className="team-shape4">
                        <img src="assets/img/shape/shape2.png" alt="Images" />
                    </div>
                </div>
            </div>
            {/* Team Area End */}
        </>

    )
}

export default Team