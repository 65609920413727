import React from 'react'
import AboutUs from './AboutUs'
import Footer from './Footer'
import Header from './Header'
import Hero from './Hero'
import News from './News'
import Projects from './Projects'
import Services from './Services'
import Team from './Team'

const Home = () => {
    return (
        <>
            {/* Pre Loader */}

            <div className="preloader">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="spinner" />
                    </div>
                </div>
            </div>

            {/* End Pre Loader */}

            <Header />
            <Hero />
            <AboutUs />
            <Services />
            <Projects />
            <Team />
            <News />
            <Footer />
        </>

    )
}

export default Home