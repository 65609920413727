import React from 'react'

const Footer = () => {
    return (
        <>
            {/* Footer Area */}
            <footer className="footer-area footer-bg2 pt-100 pb-70">
                <div className="container">
                    <div className="footer-top">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-sm-6">
                                <div className="footer-img">
                                    <img
                                        src="/assets/img/logo/logo.png"
                                        className="footer-img1"
                                        alt="Images"
                                    />
                                    <img
                                        src="/assets/img/logo/logo.png"
                                        className="footer-img2"
                                        alt="Images"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="footer-social-icon">
                                    <ul className="social-link">
                                        <li>
                                            <a href="https://web.facebook.com/XtendEg" target="_blank">
                                                <i className="bx bxl-facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/xtend-eg/" target="_blank">
                                                <i className="bx bxl-linkedin" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://web.telegram.org/#/im?p=g358365009" target="_blank">
                                                <i className="bx bxl-telegram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-youtube" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-midal pt-45 pb-70">
                        <div className="row">
                            <div className="col-lg-5 col-sm-5">
                                <div className="footer-widget">
                                    <h3>Services</h3>
                                    <ul className="footer-list">
                                        <li>
                                            <a href="/service/app_development">Custom software development</a>
                                        </li>
                                        <li>
                                            <a href="/service/web_development">Web development</a>
                                        </li>
                                        <li>
                                            <a href="/service/web3">Web3 development</a>
                                        </li>
                                        <li>
                                            <a href="/service/ai">Artificial intelligence and machine learning</a>
                                        </li>
                                        <li>
                                            <a href="/service/mobile_app_development">Mobile app development</a>
                                        </li>
                                        <li>
                                            <a href="/service/hosting">Domain, Hosting, and VPS Services</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-1 col-sm-7">
                                <div className="footer-widget">
                                    {/* <h3>Company</h3>
                                    <ul className="footer-list">
                                        <li>
                                            <a href="#">About the Company</a>
                                        </li>
                                        <li>
                                            <a href="#">For Customers</a>
                                        </li>
                                       
                                        <li>
                                            <a href="#">Careers &amp; Reviews</a>
                                        </li>
                                        <li>
                                            <a href="#">Sitemap</a>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                            <div className="col-lg-1 col-sm-5">
                                <div className="footer-widget ps-5">
                                    {/* <h3>Learning</h3>
                                    <ul className="footer-list">
                                        <li>
                                            <a href="#">About the Company</a>
                                        </li>
                                       
                                        <li>
                                            <a href="#">Careers &amp; Reviews</a>
                                        </li>
                                        <li>
                                            <a href="#">Sitemap</a>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                            <div className="col-lg-5 col-sm-7">
                                <div className="footer-widget">
                                    <h3>Newsletter</h3>
                                    <p>To get the latest news and latest up- dates from us</p>
                                    <div className="newsletter-area">
                                        <form
                                            className="newsletter-form"
                                            data-toggle="validator"
                                            method="POST"
                                        >
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Your Email*"
                                                name="EMAIL"
                                                required=""
                                                autoComplete="off"
                                            />
                                            <button className="default-btn" type="submit">
                                                Subscribe
                                            </button>
                                            <div id="validator-newsletter" className="form-result" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copy-right-area">
                        <div className="copy-right-text text-center">
                            <p>
                                Copyright @ Xtend. All Rights Reserved by {" "}
                                <a href="https://xtend-eg.com/" target="_blank">
                                    Xtend
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Area End */}
        </>

    )
}

export default Footer