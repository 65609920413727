import React from 'react'

const Hero = () => {
    return (
        <>
            {/* Banner Area */}
            <div className="banner-area">
                <div className="container-fluid">
                    <div className="container-max-2">
                        <div className="col-lg-7">
                            <div className="banner-content">
                                {/* <div className="title">
                                    <i className="flaticon-idea" />
                                    <span>The Generator</span>
                                </div> */}
                                <h1>
                                    Possible to Link <b>Actual Customer</b>
                                </h1>
                                <p>
                                    A leading software house agency that specializes in providing innovative and cutting-edge solutions to businesses of all sizes. Our team of expert developers, designers, and strategists work tirelessly to help businesses achieve their goals using the latest and most advanced technologies on the market.
                                </p>
                                {/* <div className="banner-btn">
                                    <a href="#" className="get-btn">
                                        Get started
                                        <i className="bx bx-plus" />
                                    </a>
                                    <a href="tel:+123-098-456-098" className="call-btn">
                                        <i className="bx bx-plus" />
                                        +2-111-805-9326
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-shape-right">
                    <img src="assets/img/home1/home-one-shape.png" alt="Images" />
                </div>
                <div className="banner-img">
                    <img src="assets/img/home1/home1.png" alt="Images" />
                </div>
                {/* <div className="banner-img-2 banner-img">
                    <img src="assets/img/home-two/home-two-img.png" alt="Images" />
                </div> */}
                {/* <div className="banner-img banner-img-2">
                    <img src="assets/img/home-two/home-two-img.png" alt="Images" />
                </div> */}
                <div className="banner-shape">
                    <div className="shape1">
                        <img src="assets/img/home1/home-one-shape3.png" alt="Images" />
                    </div>
                    <div className="shape2">
                        <img src="assets/img/home1/home-one-shape1.png" alt="Images" />
                    </div>
                    <div className="shape3">
                        <img src="assets/img/home1/home-one-shape2.png" alt="Images" />
                    </div>
                    <div className="shape4">
                        <img src="assets/img/home1/home-one-shape4.png" alt="Images" />
                    </div>
                    <div className="shape5">
                        <img src="assets/img/home1/home-one-shape5.png" alt="Images" />
                    </div>
                </div>
            </div>
            {/* Banner Area End */}

        </>

    )
}

export default Hero