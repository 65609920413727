import React from 'react'
import { useParams, Link } from "react-router-dom";
import { services } from '../db/db'

const ServiceDetails = () => {
    const { id } = useParams();

    return (
        <>
            {/* Inner Banner Area */}
            <div className="inner-banner">
                <div className="container">
                    <div className="inner-title text-center">
                        <h3>{services[id].title}</h3>
                        <ul>
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <i className="bx bx-chevron-right" />
                            </li>
                            <li>{services[id].title}</li>
                        </ul>
                    </div>
                </div>
                <div className="inner-banner-shape">
                    <div className="shape-one">
                        <img src="/assets/img/inner-banner/banner-shape1.png" alt="Images" />
                    </div>
                    <div className="shape-two">
                        <img src="/assets/img/inner-banner/banner-shape2.png" alt="Images" />
                    </div>
                    <div className="shape-three">
                        <img src="/assets/img/inner-banner/banner-shape3.png" alt="Images" />
                    </div>
                    <div className="inner-banner-dots-2">
                        <img src="/assets/img/shape/dots-shape.png" alt="Images" />
                    </div>
                </div>
            </div>
            {/* Inner Banner Area End */}
            {/* Service Details Area */}
            <div className="service-details-area pt-50 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="service-left">
                                <div className="service-content">
                                    <img src="/assets/img/service/service-details.jpg" alt="Images" />
                                    <h2>{services[id].title}</h2>
                                    <p style={{ whiteSpace: "pre-line" }}>
                                        {services[id].description}
                                    </p>
                                    {/* <p>

                                        Web development is an essential service that involves the creation and maintenance of websites. A web developer uses their knowledge of programming languages such as HTML, CSS, and JavaScript to design and develop the user interface, structure, and functionality of a website. They need to ensure that the website is optimized for web browsers and is responsive across different devices.
                                    </p>
                                    <p>
                                        Web development also involves backend development, which includes managing databases, server-side scripting, and APIs. These features enable the website to perform complex functions such as data processing, user authentication, and interactions with other web services.

                                    </p>
                                    <p>
                                        In addition to technical skills, web developers need to have an understanding of web design principles, user experience, and search engine optimization. This ensures that the website is visually appealing, easy to use, and accessible to users. Web development also plays an important role in establishing a brand's online presence, building customer trust, and generating leads and conversions.

                                    </p>
                                    <p>
                                        With the growing digitalization, web development has become an increasingly critical service for businesses of all sizes. It enables businesses to reach customers globally, showcase products and services, provide customer support, and facilitate online transactions. The demand for web development services continues to grow as technology evolves, and businesses look to stay ahead of the competition by providing innovative and engaging digital experiences for their customers.

                                    </p> */}

                                </div>



                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="service-widget-right">
                                <div className="widget-category">
                                    <h3>Other Services</h3>
                                    <ul>
                                        <li>
                                            <a href="/service/ai">AI &amp; ML Development</a>
                                        </li>
                                        <li>
                                            <a href="/service/app_development">App Development</a>
                                        </li>
                                        <li>
                                            <a href="/service/web3">Web3</a>
                                        </li>
                                        <li>
                                            <a href="/service/mobile_app_development">Mobile app development</a>
                                        </li>
                                        <li>
                                            <a href="/service/hosting">Domain, Hosting, and VPS Services</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="contact-widget">
                                    <h2>Contact Info</h2>
                                    <ul>
                                        <li>
                                            <i className="flaticon-telephone" />
                                            <div className="content">
                                                <h3>Phone: </h3>
                                                <span>
                                                    <a href="tel:+20111859326">+20 109 327 7721</a>
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <i className="flaticon-email-1" />
                                            <div className="content">
                                                <h3>Email:</h3>
                                                <span>
                                                    <a href="mailto:contact@xtend-eg.com">contact@xtend-eg.com</a>
                                                </span>
                                            </div>
                                        </li>
                                        {/* <li>
                                            <i className="flaticon-planet-earth" />
                                            <div className="content">
                                                <h3>Location:</h3>
                                                <span>112/7 New York, USA</span>
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Service Details Area End */}

        </>

    )
}

export default ServiceDetails